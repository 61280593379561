<template>
  <div>
    <Header />
    <div class="page-title">
      <div class="container">
        <router-link to="/blog" class="title">Blog</router-link>
      </div>
    </div>
    <section>
      <div class="container">
        <div v-show="loadingPost" class="flex-center mt-3">
          <div class="loading2"></div>
        </div>
        <article v-show="!loadingPost" class="post-holder">
          <transition enter-active-class="animated fadeInLeft">
            <div v-show="!loadingPost" class="category">
              {{ post.blogCategory.name }}
            </div>
          </transition>

          <transition enter-active-class="animated zoomIn">
            <h1 v-show="!loadingPost" class="title">{{ post.title }}</h1>
          </transition>
          <transition enter-active-class="animated fadeIn">
            <time v-show="!loadingPost" pubdate>{{
              post.startDate | moment("DD/MM/YYYY")
            }}</time>
          </transition>
          <p class="summary" v-html="post.summary"></p>
          <transition enter-active-class="animated fadeInRight">
            <img v-show="!loadingPost" :src="post.image" class="post" />
          </transition>
          <transition enter-active-class="animated zoomIn">
            <div
              v-show="!loadingPost"
              class="post-description-intern mt-1"
              v-html="post.description"
            ></div>
          </transition>

          <div class="comments mt-2">
            <Disqus shortname="microquimicatradecorp" />
          </div>
        </article>
        <div class="related-holder mt-3-mobile">
          <div class="subtitle mobile12">VEJA TAMBÉM</div>
          <div v-show="loadingRelatedPosts" class="flex-center mt-3">
            <div class="loading2"></div>
          </div>
          <transition enter-active-class="animated fadeInRight">
            <div v-show="!loadingRelatedPosts">
              <article
                v-for="post in relatedPosts"
                :key="post.id"
                class="related-post"
              >
                <router-link :to="`/blog/post/${post.slug}`">
                  <div
                    class="img-bg"
                    :style="`background-image:url(${post.image});`"
                  ></div>
                  <h1>{{ post.title }}</h1>
                </router-link>
              </article>
            </div>
          </transition>
        </div>
      </div>
    </section>
    <Footer />
  </div>
</template>
<script>
import http from "@/http";
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import { Disqus } from "vue-disqus";
export default {
  name: "BlogPost",
  components: {
    Header,
    Footer,
    Disqus,
  },
  data() {
    return {
      post: {
        title: "",
        summary: "",
        description: "",
        image: "",
        starDate: "2020-12-15",
        blogCategory: {
          id: "",
          name: "",
        },
      },
      relatedPosts: [],
      loadingPost: true,
      loadingRelatedPosts: true,
    };
  },
  created() {
    this.loadPost();
  },
  methods: {
    loadPost() {
      http
        .get(`post?id=${this.$route.params.slug}`)
        .then((response) => {
          this.post = response.data;
          this.loadRelatedPosts();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loadingPost = false;
        });
    },
    loadRelatedPosts() {
      http
        .get(
          `post/List?orderBy=startDate&take=3&blogCategoryId=${this.post.blogCategory.id}&currentId=${this.post.id}`
        )
        .then((res) => {
          this.relatedPosts = res.data.list;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loadingRelatedPosts = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
section .container {
  display: grid;
  grid-template-columns: 0.7fr 0.22fr;
  justify-content: space-between;
}

.post-holder {
  position: relative;
  .category {
    color: var(--secondary);
  }
  h1.title {
    font-size: 2em;
  }
  time {
    position: relative;
    display: block;
    color: var(--yellow2);
    font-style: italic;
    margin-top: 10px;
  }
  .summary {
    position: relative;
    font-size: 1.2em;
    margin-top: 10px;
  }
  img.post {
    position: relative;
    max-width: 100%;
    margin-top: 10px;
  }
}

.related-holder {
  .subtitle {
    font-family: fontMedium;
    font-size: 1.1em;
    color: var(--yellow2);
  }
  .related-post {
    position: relative;
    width: 100%;
    margin-top: 30px;
    &:hover {
      .img-bg {
        background-color: #7e794f;
      }
      h1 {
        color: var(--yellow2);
      }
    }
    .img-bg {
      position: relative;
      position: 100%;
      padding-top: 65%;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      background-color: #808080;
      background-blend-mode: overlay;
      transition: background-color 0.35s ease;
    }
    h1 {
      font-size: 1.1em;
      margin-top: 10px;
      transition: color 0.35s ease;
    }
  }
}

@media screen and(max-width: 720px) {
  section .container {
    display: block;
  }
  .related-holder {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .related-post {
      width: 48%;
    }
  }
}

@media only screen and (min-width: 721px) and (max-width: 1159px) {
  section .container {
    grid-template-columns: 0.72fr 0.25fr;
  }
}
</style>
